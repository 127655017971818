<template>
  <b-card v-if="quote.options.length > 0" class="invoice-preview-card pagebreak" no-body>
    <!-- Header -->
    <b-card-body class="invoice-padding pb-0">
      <div
        class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
      >
        <!-- Header: Left Content -->
        <div />

        <!-- Header: Right Content -->
        <div class="mt-md-0 mt-2">
          <h4 class="invoice-numbercs mb-2">Estimate #{{ quote.tfr.tfr }}</h4>
        </div>
      </div>

      <h2>Base Contract Additions</h2>
      <p>
        Base Contracts Additions are the prices for materials and work above that included
        in the Base Contract and Construction Specifications. These prices are eligible
        for a fixed price contact when executed with a CCDC 2 or CCDC14.
      </p>
      <!-- Hard Construction: Table -->
      <div class="mt-3" v-if="hardCnstrItems.length > 0">
        <b-table-simple
          fixed
          style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
        >
          <b-thead>
            <b-tr>
              <b-th class="table-background-color" colspan="9"> Hard Construction </b-th>
              <b-th class="table-background-color" colspan="2">
                {{ $t("quote.pricePerSqft", "en") }}
              </b-th>
              <b-th class="table-background-color" colspan="2">
                {{ $t("quote.subtotal", "en") }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              class="border-group-details"
              v-for="(c, index) in hardCnstrItems"
              :key="index"
            >
              <b-td colspan="9">
                <div>{{ c.name }}</div>
                <div class="text-muted">
                  {{ c.details }}
                </div>
              </b-td>
              <b-td
                :class="[c.total_price < 0 ? 'credit ' : ''] + 'row-label'"
                colspan="2"
                style="font-weight: normal"
              >
                {{
                  Number(c.total_price / quote.sqft).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </b-td>
              <b-td
                :class="[c.total_price < 0 ? 'credit ' : '']"
                total
                cost
                colspan="2"
                style="font-weight: normal"
              >
                {{
                  Number(c.total_price).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr class="border-footer">
              <b-td class="row-label" colspan="9" style="">
                Hard Construction Subtotal
              </b-td>
              <b-td class="row-label" colspan="2"
                >{{
                  Number(hardConstrnSubtotal / quote.sqft).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </b-td>
              <b-td class="row-label" colspan="2"
                >{{
                  Number(hardConstrnSubtotal).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </div>
      <!-- Furniture: Table -->
      <div class="mt-3" v-if="ffeItems.length > 0">
        <b-table-simple
          fixed
          class="mt-0 pt-0"
          style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
        >
          <b-thead>
            <b-tr>
              <b-th class="table-background-color" colspan="9"> Furniture </b-th>
              <b-th class="table-background-color" colspan="2">
                {{ $t("quote.pricePerSqft", "en") }}
              </b-th>
              <b-th class="table-background-color" colspan="2">
                {{ $t("quote.subtotal", "en") }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              class="border-group-details"
              v-for="(c, index) in ffeItems"
              :key="index"
            >
              <b-td colspan="9">
                <div>{{ c.name }}</div>
                <div class="text-muted">
                  {{ c.details }}
                </div>
              </b-td>
              <b-td
                :class="[c.total_price < 0 ? 'credit ' : ''] + 'row-label'"
                colspan="2"
                style="font-weight: normal"
              >
                {{
                  Number(c.total_price / quote.sqft).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </b-td>
              <b-td
                :class="[c.total_price < 0 ? 'credit ' : '']"
                total
                cost
                colspan="2"
                style="font-weight: normal"
              >
                {{
                  Number(c.total_price).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr class="border-footer">
              <b-td class="row-label" colspan="9" style=""> Furniture Subtotal </b-td>
              <b-td class="row-label" colspan="2"
                >{{
                  Number(ffeSubtotal / quote.sqft).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </b-td>
              <b-td class="row-label" colspan="2"
                >{{
                  Number(ffeSubtotal).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </div>
      <!-- AV & IT: Table -->
      <div class="mt-3" v-if="avitItems.length > 0">
        <b-table-simple
          fixed
          class="mt-0 pt-0"
          style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
        >
          <b-thead>
            <b-tr>
              <b-th class="table-background-color" colspan="9"> AV & IT </b-th>
              <b-th class="table-background-color" colspan="2">
                {{ $t("quote.pricePerSqft", "en") }}
              </b-th>
              <b-th class="table-background-color" colspan="2">
                {{ $t("quote.subtotal", "en") }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              class="border-group-details"
              v-for="(c, index) in avitItems"
              :key="index"
            >
              <b-td colspan="9">
                <div>{{ c.name }}</div>
                <div class="text-muted">
                  {{ c.details }}
                </div>
              </b-td>
              <b-td
                :class="[c.total_price < 0 ? 'credit ' : ''] + 'row-label'"
                colspan="2"
                style="font-weight: normal"
              >
                {{
                  Number(c.total_price / quote.sqft).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </b-td>
              <b-td
                :class="[c.total_price < 0 ? 'credit ' : '']"
                total
                cost
                colspan="2"
                style="font-weight: normal"
              >
                {{
                  Number(c.total_price).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr class="border-footer">
              <b-td class="row-label" colspan="9" style="">AV & IT Subtotal</b-td>
              <b-td class="row-label" colspan="2"
                >{{
                  Number(avitSubtotal / quote.sqft).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </b-td>
              <b-td class="row-label" colspan="2"
                >{{
                  Number(avitSubtotal).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </div>
      <!-- Soft Costs: Table -->
      <div class="mt-3" v-if="softItems.length > 0">
        <b-table-simple
          fixed
          class="mt-0 pt-0"
          style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
        >
          <b-thead>
            <b-tr>
              <b-th class="table-background-color" colspan="9"> Soft Costs </b-th>
              <b-th class="table-background-color" colspan="2">
                {{ $t("quote.pricePerSqft", "en") }}
              </b-th>
              <b-th class="table-background-color" colspan="2">
                {{ $t("quote.subtotal", "en") }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              class="border-group-details"
              v-for="(c, index) in softItems"
              :key="index"
            >
              <b-td colspan="9">
                <div>{{ c.name }}</div>
                <div class="text-muted">
                  {{ c.details }}
                </div>
              </b-td>
              <b-td
                :class="[c.total_price < 0 ? 'credit ' : ''] + 'row-label'"
                colspan="2"
                style="font-weight: normal"
              >
                {{
                  Number(c.total_price / quote.sqft).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </b-td>
              <b-td
                :class="[c.total_price < 0 ? 'credit ' : '']"
                total
                cost
                colspan="2"
                style="font-weight: normal"
              >
                {{
                  Number(c.total_price).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr class="border-footer">
              <b-td class="row-label" colspan="9" style="">Soft Costs Subtotal</b-td>
              <b-td class="row-label" colspan="2"
                >{{
                  Number(softSubtotal / quote.sqft).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </b-td>
              <b-td class="row-label" colspan="2"
                >{{
                  Number(softSubtotal).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </div>
      <!-- Spacer -->
      <hr class="invoice-spacing mt-2" style="border: 1px solid #82868b" />
      <b-table-simple
        fixed
        style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
        table-class="text-nowrap"
      >
        <b-tfoot>
          <b-tr>
            <b-td
              class="row-label project-total-label"
              colspan="9"
              style="border-top: none"
            >
              <h4 style="font-weight: 600">Base Contract Additions Total</h4>
            </b-td>
            <b-td
              class="row-label project-total-number"
              colspan="2"
              style="border-top: none"
            >
              {{
                Number(
                  (hardConstrnSubtotal + ffeSubtotal + avitSubtotal + softSubtotal) /
                    quote.sqft
                ).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}
            </b-td>
            <b-td class="project-total-number" colspan="2" style="border-top: none">
              {{
                Number(
                  hardConstrnSubtotal + ffeSubtotal + avitSubtotal + softSubtotal
                ).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </b-card-body>

    <!-- Note -->
    <!-- Note -->
    <!-- <b-card-body class="invoice-padding pt-0">
                  <span class="disclaimer">This pricing does not contemplate liquidated damages. If liquidated damages are required, a premium will apply.<br>
                      Quote does NOT include <span v-if="quote.opportunity.geography == 'Toronto'">HST.</span><span v-else>include GST + QST.</span> <br>
                      Quote does NOT include any landlord required reviews and backcharges (incl. after hour fees, commissioning, security, engineer reviews, etc.).<br>
                      Changes to the scope of work listed above will be priced separately and agreed to in writing before additional work is performed. </span>
              </b-card-body> -->
    <b-card-body class="invoice-padding notprintable pt-0 footer-quote my-3">
      <span class="confidential-line float-right">{{
        $t("quote.confidential", "en")
      }}</span>
    </b-card-body>
    <hr class="bg-primary p-0 m-0 hr_bottom" style="height: 8px" />
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCol,
  BButton,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
  VBToggle,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardBody,
    BCol,
    BButton,
    BRow,
    BTableSimple,
    BTbody,
    BTd,
    BTfoot,
    BTh,
    BThead,
    BTr,
  },
  props: {
    quote: {
      type: Object,
      default: [],
    },
    siteConditionsConstructionSubtotal: {
      type: Number,
      default: 0,
    },
    optionsAvItSubtotal: {
      type: Number,
      default: 0,
    },
    optionsConstructionSubtotal: {
      type: Number,
      default: 0,
    },
    options: {
      type: Array,
      default: 0,
    },
    showOptions: {
      type: Boolean,
      default: 0,
    },
    siteConditions: {
      type: Array,
      default: 0,
    },
    showSiteConditions: {
      type: Boolean,
      default: 0,
    },
    optionsFfeSubtotal: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      constCategories: [
        "Demolition",
        "Doors",
        "Drywall",
        "Glazing",
        "Flooring & Tile",
        "Electrical",
        "Mechanical",
        "Kitchen & Millwork",
        "Wall Finishes",
        "Security & Doors",
        "Closeout & Other",
      ],
      ffeCategories: ["Systems Furniture", "Ancillary"],
    };
  },
  computed: {
    hardConstrnSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "Construction")
        .reduce((total, obj) => obj.total_price + total, 0);
      return result;
    },
    hardCnstrItems() {
      let result = this.quote.options.filter((c) => c.budget_group == "Construction");
      return result;
    },
    ffeSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "FF&E")
        .reduce((total, obj) => obj.total_price + total, 0);
      return result;
    },
    ffeItems() {
      let result = this.quote.options.filter((c) => c.budget_group == "FF&E");
      return result;
    },
    avitSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "AV/IT")
        .reduce((total, obj) => obj.total_price + total, 0);
      return result;
    },
    avitItems() {
      let result = this.quote.options.filter((c) => c.budget_group == "AV/IT");
      return result;
    },
    softSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "Soft Costs")
        .reduce((total, obj) => obj.total_price + total, 0);
      return result;
    },
    softItems() {
      let result = this.quote.options.filter((c) => c.budget_group == "Soft Costs");
      return result;
    },
  },
  mounted() {},
  methods: {
    ffeTotals(option) {
      let total = 0;
      if (option == "Systems Furniture") {
        total = +this.quote.ffe_spec_quote
          .filter((x) => x.subcategory != "Ancillary")
          .reduce((total, obj) => obj.supply_price * obj.qty + total, 0);
      }
      if (option == "Ancillary") {
        total = +this.quote.ffe_spec_quote
          .filter((x) => x.subcategory == "Ancillary")
          .reduce((total, obj) => obj.supply_price * obj.qty + total, 0);
      }
      if (option == "Delivery and Installation") {
        total = +this.quote.ffe_spec_quote.reduce(
          (total, obj) => obj.total_delivery_install + total,
          0
        );
      }
      return total;
    },
    avitTotals(option) {
      let total = 0;
      total = this.quote.avit_spec_quote
        .filter((x) => x.subcategory == option)
        .reduce((total, obj) => obj.supply_price * obj.qty + total, 0);
      return total;
    },
    avitDI(option) {
      let total = 0;
      total = this.quote.avit_spec_quote
        .filter((x) => x.subcategory == option)
        .reduce((total, obj) => obj.total_delivery_install * obj.qty + total, 0);
      return total;
    },
    avitGlobalSubTotal() {
      let sum = 0;
      sum += this.avitTotals("IT");
      sum += this.avitTotals("AV");
      sum += this.avitDI("IT");
      sum += this.avitDI("AV");
      return sum;
    },
  },
};
</script>

<style lang="scss" scoped></style>
