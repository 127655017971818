<template>
  <b-card class="invoice-preview-card pagebreak" no-body>
    <b-card-body class="invoice-padding pb-0">
      <div
        class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
      >
        <!-- Header: Left Content -->
        <div />

        <!-- Header: Right Content -->
        <div class="mt-md-0 mt-2">
          <h4 class="invoice-numbercs mb-2">Estimate #{{ quote.tfr.tfr }}</h4>
        </div>
      </div>
      <div>
        <h2
          style="
            padding-bottom: 1rem;
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          "
        >
          Scope of Interior Design Services
        </h2>
        <div>
          <b-table-simple
            fixed
            class="mt-0 pt-0"
            style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
          >
            <b-thead>
              <b-tr>
                <b-th style="width: 200px" class="table-background-color">Offering</b-th>
                <b-th style="width: 500px" class="table-background-color">Services & Deliverables</b-th>
                <b-th class="table-background-color">Design, PM & Arch</b-th>
                <b-th class="table-background-color">M&E</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <!--Design Concept / Schematic Design  -->
              <b-tr>
                <b-td style="vertical-align: top !important"
                  ><b>Design Concept / Schematic Design</b></b-td
                >
                <b-td>
                  <div>
                    <b>Services</b> <br />
                    <ul>
                      <li>Client kick-off meeting</li>
                      <li>Prepare a single visioning package</li>
                      <li>Client visioning review meeting</li>
                      <li>Review of existing site conditions</li>
                      <li>Develop up to two design concepts</li>
                      <li>Client concept review meeting</li>
                      <li>Prepare single revision of design concept (if required)</li>
                      <li>
                        Prepare project estimate update for client approval (if required)
                      </li>
                    </ul>
                  </div>
                  <div class="mt-1">
                    <b>Deliverables</b><br />
                    <ul>
                      <li>Visioning document</li>
                      <li>Design concept document</li>
                      <li>Updated test-fit floor plans (if required)</li>
                      <li>Updated project estimate (if required)</li>
                      <li>Preliminary project timeline.</li>
                    </ul>
                  </div>
                </b-td>
                <b-td class="text-left" style="vertical-align: top !important">
                  <feather-icon icon="CheckIcon" class="check_icon"
                /></b-td>
                <b-td class="text-left" style="vertical-align: top !important"
                  ><feather-icon icon="CheckIcon" class="check_icon"
                /></b-td>
              </b-tr>
            </b-tbody>
            <b-tbody>
              <!-- Permit Drawings  -->
              <b-tr>
                <b-td style="vertical-align: top !important"><b>Permit Drawings</b></b-td>
                <b-td>
                  <div>
                    <b>Services</b> <br />
                    <ul>
                      <li>
                        Create permit drawings as required by the AHJ (authority having
                        jurisdiction)
                      </li>
                      <li>Submission of permit drawings</li>
                    </ul>
                  </div>
                  <div class="mt-1">
                    <b>Deliverables</b><br />
                    <ul>
                      <li>Copy of permit drawings</li>
                      <li>Evidence of permit submission</li>
                    </ul>
                  </div>
                </b-td>
                <b-td class="text-left" style="vertical-align: top !important">
                  <feather-icon icon="CheckIcon" class="check_icon"
                /></b-td>
                <b-td class="text-left" style="vertical-align: top !important"
                  ><feather-icon icon="CheckIcon" class="check_icon"
                /></b-td>
              </b-tr>
            </b-tbody>
            <b-tbody>
              <!--Design Development  -->
              <b-tr>
                <b-td style="vertical-align: top !important"
                  ><b>Design Development</b></b-td
                >
                <b-td>
                  <div>
                    <b>Services</b> <br />
                    <ul>
                      <li>
                        Up to two Design Development sessions with a Clearspace Project
                        Manager and Designer
                      </li>
                      <li>
                        2 (two) minor revisions following each of the Design Package
                        Presentations
                      </li>
                      <li>System Furniture selection</li>
                      <li>
                        Ancillary furniture, fixtures and equipment selection and
                        specification
                      </li>
                      <li>Update project estimate for client approval (if required)</li>
                    </ul>
                  </div>
                  <div class="mt-1">
                    <b>Deliverables</b><br />
                    <ul>
                      <li>Two design presentations for client approval</li>
                      <li>Final design package</li>
                      <li>Final furniture specification and order</li>
                      <li>Updated project estimate (if required)</li>
                    </ul>
                  </div>
                </b-td>
                <b-td class="text-left" style="vertical-align: top !important">
                  <feather-icon icon="CheckIcon" class="check_icon"
                /></b-td>
                <b-td class="text-left" style="vertical-align: top !important"
                  ><feather-icon icon="CheckIcon" class="check_icon"
                /></b-td>
              </b-tr>
            </b-tbody>
            <b-tbody>
              <!--Design Development  -->
              <b-tr>
                <b-td style="vertical-align: top !important"
                  ><b>Construction / Contract Documentation</b></b-td
                >
                <b-td>
                  <div>
                    <b>Services</b> <br />
                    <ul>
                      <li>Preparation of tender package and supporting documentation</li>
                      <li>Preparation of Landlord review documentation</li>
                      <li>Preparation of Issue for Construction documentation</li>
                    </ul>
                  </div>
                  <div class="mt-1">
                    <b>Deliverables</b><br />
                    <ul>
                      <li>
                        Digital copy of tender packages, including all supporting
                        documentation and drawings
                      </li>
                    </ul>
                  </div>
                </b-td>
                <b-td class="text-left" style="vertical-align: top !important">
                  <feather-icon icon="CheckIcon" class="check_icon"
                /></b-td>
                <b-td class="text-left" style="vertical-align: top !important"
                  ><feather-icon icon="CheckIcon" class="check_icon"
                /></b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
      <b-card-body class="invoice-padding pt-0 footer-quote my-3 notprintable">
        <span class="confidential-line float-right">{{
          $t("quote.confidential", "en")
        }}</span>
      </b-card-body>
    </b-card-body>
    <hr class="bg-primary p-0 m-0 hr_bottom" style="height: 8px" />
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCol,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
  VBToggle,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableSimple,
    BButton,
    BTr,
    BTh,
    BThead,
    BTfoot,
    BTd,
    BTbody,
    Logo,
  },
  props: ["quote"],
  data() {
    return {
      quoteId: this.$route.params.quoteid,
      comments: {},
      options: {},
      siteConditions: {},
      show: true,
      newComment: "",
      assumptionsExclusions: {},
    };
  },
  beforeMount() {
    this.assumptionsExclusions = this.quote.assumptions_exclusions;
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
.notes_assumptions_list > li {
  //   font-family:  "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  color: #6e6b7b;
  padding: 10px 5px;
}
</style>
<style>
.cell-styling {
  height: 33px;
}
.cell-styling-double {
  height: 50px;
}
.first-column {
  padding-right: 28px;
  padding-left: 28px;
}
.secondary-column {
  padding-right: 28px;
  padding-left: 28px;
}
.first-row-border {
  border-top: 1px solid #ebe9f1;
  border-bottom: 1px solid #ebe9f1;
}
.following-row-border {
  border-bottom: 1px solid #ebe9f1;
}
.disclaimer-exclusions {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ea5455;
}
.other-notes-title {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  /* identical to box height, or 192% */
  padding-top: 29px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}
.other-notes-text {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.card-body {
  padding: 28px;
}
.check_icon {
  width: 20px;
  height: 20px;
}
ul {
  padding-left: 22px;
}
</style>
