<template>
  <div>
    <div
      style="background-color: rgba(50, 61, 76, 1)"
      class="invoice_header text-white invoice-padding m-0 p-0"
    >
      <b-card-body class="invoice-padding mx-1 py-4">
        <div
          class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
        >
          <!-- Header: Left Content -->
          <div>
            <div class="logo-wrapper">
              <logo />
            </div>
            <p class="card-text mb-25 mt-2 base-paragraph">Clearspace Offices Inc.</p>
            <p class="card-text mb-25 base-paragraph">
              {{
                quote.geography != "Montréal" ? "901-20 Victoria Street" : montrealAddress
              }}
            </p>
            <p class="card-text mb-0 base-paragraph">
              {{
                quote.geography != "Montréal"
                  ? "Toronto, ON, Canada M5C 2N8"
                  : "Montréal, QC, Canada H3B 2N2"
              }}
            </p>
            <p class="card-text mb-0 base-paragraph" v-if="quote.geography == 'Montréal'">
              {{ $t("quote.quebecEnterpriseNumber", "en") }}
            </p>
            <p class="card-text mb-0 base-paragraph" v-if="quote.geography == 'Montréal'">
              {{ $t("quote.RbqLicense", "en") }}
            </p>
          </div>

          <!-- Header: Right Content -->
          <div class="mt-md-0 mt-2">
            <h4 class="invoice-numbercs mb-2 text-white" style="text-align: end">
              Quote #{{ quote.tfr.tfr }}
            </h4>
            <div class="invoice-date-wrapper" style="width: 220px">
              <div class="row">
                <div class="col-6">
                  <p
                    class="invoice-date-title my-0 py-0"
                    style="padding-bottom: 10px !important"
                  >
                    {{ $t("quote.issueDate", "en") }}
                  </p>
                </div>
                <div class="col-6">
                  <p class="invoice-date text-right">
                    {{ quote.issue_date }}
                  </p>
                </div>
              </div>
            </div>
            <div class="invoice-date-wrapper" style="width: 220px">
              <div class="row">
                <div class="col-6">
                  <p class="invoice-date-title">
                    {{ $t("quote.expiryDate", "en") }}:
                  </p>
                </div>
                <div class="col-6">
                  <p class="invoice-date text-right">
                    {{ new Date(quote.expiration_date).toLocaleDateString("en-CA") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card-body>
    </div>
    <!-- Invoice Client & Payment Details -->
    <b-card-body class="invoice-padding mt-3 pt-0">
      <b-row class="invoice-spacing mx-1">
        <!-- Col: Invoice To -->
        <b-col class="pl-0" cols="8">
          <h6 class="header-label">
            {{ $t("quote.address", "en") }}
          </h6>
          <h2
            class="card-text mb-25"
            style="
              font-weight: 500 !important;
              font-size: 28px !important;
              line-height: 26px !important;
              color: #636363 !important;
              text-transform: uppercase;
            "
          >
            {{ quote.address }}
          </h2>
          <h2
            class="card-text mb-25"
            style="
              font-weight: 500 !important;
              font-size: 28px !important;
              line-height: 26px !important;
              color: #636363 !important;
              text-transform: uppercase;
            "
          >
            {{ quote.geography }}
            <!-- {{quote.project.city + ', ' + quote.project.state + ', Canada ' + quote.project.postcode}} -->
          </h2>
          <h6 class="mt-2 header-label">
            {{ $t("quote.size", "en") }}
          </h6>
          <p class="card-text mb-2">
            {{
              Number(quote.sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 0,
              }) +
              " " +
              $t("quote.sqft", "en")
            }}
          </p>
        </b-col>
        <!-- <b-col
                class=""
                cols="4"
              >
                <h6 class="mb-1 header-label">
                  {{$t('quote.billTo', quote.language)}}
                </h6>
                <p class="card-text mb-25">
                  {{ quote.opportunity.account_name }}
                </p>
                <p class="card-text mb-25">
                  {{ quote.opportunity.contact_name }}
                </p>
                <p class="card-text mb-25">
                  {{ quote.opportunity.contact_phone }}
                </p>
                <p class="card-text mb-0">
                  {{ quote.opportunity.contact_email }}
                </p>
              </b-col> -->

        <!-- Col: Payment Details -->
        <b-col class="mt-xl-0 mt-2 d-flex justify-content-xl-end" cols="4">
          <div class="width-100p">
            <h6 class="mb-2 header-label">
              {{ $t("quote.quoteSummary", "en") }}
            </h6>
            <p class="header-label-grey">
              {{ $t("quote.dollarPerSqft", "en") }}
            </p>
            <table class="width-100p">
              <tbody>
                <tr>
                  <td class="pr-1 pb-1" colspan="4">
                    {{ $t("quote.turnkeySuite", "en") }}
                  </td>
                  <td class="header-subtotal pr-0 pb-1 text-right" colspan="1">
                    {{
                      "$" +
                      Number(
                        quote.construction_cost_per_sqft +
                          quote.site_conditions_construction_price_per_sqft +
                          quote.options_construction_price_per_sqft +
                          quote.construction_buffer * 1 +
                          Number(
                            (!quote.ffe_toggle ? 0 : quote.ffe_price_per_sqft) +
                              quote.options_ffe_price_per_sqft +
                              Number(
                                (!quote.avit_toggle ? 0 : quote.avit_price_per_sqft) +
                                  quote.options_avit_price_per_sqft
                              ) +
                              Number(
                                quote.construction_pm_buffer * 1 +
                                  quote.design_ame_buffer * 1 +
                                  Number(quote.engineering_fee_price) * 1
                              ) +
                              Number(quote.permit_fees_cost_per_sqft)
                          )
                      ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                      }) +
                      ""
                    }}
                  </td>
                </tr>
                <tr v-if="Number(Math.abs(quote.site_conditions_price_per_sqft)) > 0">
                  <td class="pr-1 pb-1" colspan="4">
                    {{ $t("quote.siteConditions", "en") }}
                  </td>
                  <td class="header-subtotal pr-0 pb-1 text-right" colspan="1">
                    {{
                      Number(
                        Math.abs(quote.site_conditions_price_per_sqft)
                      ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </td>
                </tr>
                <tr v-if="Number(Math.abs(quote.options_price_per_sqft)) > 0">
                  <td class="pr-1 pb-1" colspan="4">
                    {{ $t("quote.additionalScopeAndUpgrades", "en") }}
                  </td>
                  <td class="header-subtotal pr-0 pb-1 text-right" colspan="1">
                    {{
                      Number(Math.abs(quote.options_price_per_sqft)).toLocaleString(
                        "en-CA",
                        {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        }
                      )
                    }}
                  </td>
                </tr>
                <tr style="border-top: 1px solid #d8d6de">
                  <td class="pr-1 pb-1 pt-2 project-sqft" colspan="4">
                    {{ $t("quote.projectDollarPerSqft", "en") }}
                  </td>
                  <td class="project-sqft-total pr-0 pb-1 pt-2 text-right" colspan="1">
                    {{
                      Number(
                        quote.construction_cost_per_sqft +
                          quote.site_conditions_construction_price_per_sqft +
                          quote.options_construction_price_per_sqft +
                          quote.construction_buffer * 1 +
                          Number(
                            (!quote.ffe_toggle ? 0 : quote.ffe_price_per_sqft) +
                              quote.options_ffe_price_per_sqft +
                              Number(
                                (!quote.avit_toggle ? 0 : quote.avit_price_per_sqft) +
                                  quote.options_avit_price_per_sqft
                              ) +
                              Number(
                                quote.construction_pm_buffer * 1 +
                                  quote.design_ame_buffer * 1 +
                                  Number(quote.engineering_fee_price) * 1
                              ) +
                              Number(quote.permit_fees_cost_per_sqft)
                          ) +
                          Number(quote.site_conditions_price_per_sqft) +
                          Number(quote.options_price_per_sqft)
                      ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="pr-1 pb-1 project-sqft" colspan="4">
                    {{ $t("quote.projectTotal", "en") }}
                  </td>
                  <td class="header-subtotal pr-0 pb-1 text-right" colspan="1">
                    {{
                      "$" +
                      Number(
                        Number(
                          quote.construction_buffer * 1 * quote.sqft +
                            siteConditionsConstructionSubtotal +
                            optionsConstructionSubtotal +
                            quote.construction_calc
                        ) +
                          Number(
                            (!quote.ffe_toggle ? 0 : quote.ffe_calc) + optionsFfeSubtotal
                          ) +
                          Number(
                            (!quote.avit_toggle ? 0 : quote.avit_calc) +
                              optionsAvItSubtotal
                          ) +
                          Number(
                            (quote.construction_pm_buffer * 1 +
                              quote.design_ame_buffer * 1 +
                              Number(quote.engineering_fee_price) * 1) *
                              quote.sqft
                          ) +
                          Number(quote.permit_fees) +
                          Number(quote.options_total_price) +
                          Number(quote.site_conditions_total_price)
                      ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                      }) +
                      ""
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
    <!-- Invoice Description: Table -->
    <b-table-simple
      caption-top
      responsive
      class="mt-1"
      style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
    >
      <caption class="caption-table" style="margin-left:-14px">
        {{
          $t("quote.turnkeySuite", "en")
        }}
      </caption>
      <b-thead>
        <b-tr>
          <b-th class="table-background-color" colspan="2"> SERVICES </b-th>
          <b-th class="table-background-color text-right" style="text-align:end !important " colspan="3">
            {{ $t("quote.pricePerSqft", "en") }}
          </b-th>
          <b-th class="table-background-color text-right"  style="text-align:end !important " colspan="2">
            {{ $t("quote.subtotal", "en") }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr class="border-group ">
          <b-td class="row-label" colspan="2">
            {{ $t("quote.hardConstructionCosts", "en") }}
            <div
              class="section-description"
              v-html="$t('quote.hardConstructionCostsDescription', 'en')"
            ></div>
          </b-td>
          <b-td class="row-label text-right" colspan="3" style="vertical-align: top"
            >{{
              "$" +
              Number(
                quote.construction_cost_per_sqft +
                  quote.construction_buffer * 1 +
                  quote.site_conditions_construction_price_per_sqft +
                  quote.options_construction_price_per_sqft
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
              }) +
              ""
            }}
          </b-td>
          <b-td class=" text-right" colspan="2" style="vertical-align: top"
            >{{
              "$" +
              Number(
                quote.construction_buffer * 1 * quote.sqft +
                  siteConditionsConstructionSubtotal +
                  optionsConstructionSubtotal +
                  quote.construction_calc
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
              }) +
              ""
            }}
          </b-td>
        </b-tr>
        <b-tr
          v-show="quote.ffe_toggle || quote.options_ffe_price_per_sqft > 0"
          class="border-group"
        >
          <b-td class="row-label" colspan="2">
            {{ $t("quote.furnitureAndFixtures", "en") }}
            <br />
            <div
              class="section-description"
              v-html="$t('quote.furnitureAndFixturesDescription', 'en')"
            ></div>
          </b-td>
          <b-td class="row-label text-right" colspan="3" style="vertical-align: top"
            >{{
              "$" +
              Number(
                (!quote.ffe_toggle ? 0 : quote.ffe_price_per_sqft) +
                  quote.options_ffe_price_per_sqft
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
              }) +
              ""
            }}
          </b-td>
          <b-td class=" text-right" colspan="2" style="vertical-align: top"
            >{{
              "$" +
              Number(
                (!quote.ffe_toggle ? 0 : quote.ffe_calc) + optionsFfeSubtotal
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
              }) +
              ""
            }}
          </b-td>
        </b-tr>
        <b-tr
          v-show="quote.avit_toggle || quote.options_avit_price_per_sqft > 0"
          class="border-group"
        >
          <b-td class="row-label" colspan="2">
            {{ $t("quote.avIt", "en") }}
            <div
              class="section-description"
              v-html="$t('quote.avItDescription', 'en')"
            ></div>
          </b-td>
          <b-td class="row-label text-right" colspan="3" style="vertical-align: top"
            >{{
              "$" +
              Number(
                (!quote.avit_toggle ? 0 : quote.avit_price_per_sqft) +
                  quote.options_avit_price_per_sqft
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
              }) +
              ""
            }}
          </b-td>
          <b-td class=" text-right" colspan="2" style="vertical-align: top"
            >{{
              "$" +
              Number(
                (!quote.avit_toggle ? 0 : quote.avit_calc) + optionsAvItSubtotal
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
              }) +
              ""
            }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td class="row-label border-child" colspan="2">
            {{ $t("quote.clearspaceDesignManagementFee", "en") }}
          </b-td>
          <b-td class="row-label border-child text-right" colspan="3"
            >{{
              "$" +
              Number(
                quote.construction_pm_buffer * 1 +
                  quote.design_ame_buffer * 1 +
                  Number(quote.engineering_fee_price)
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
              }) +
              ""
            }}
          </b-td>
          <b-td class="border-child text-right" colspan="2"
            >{{
              "$" +
              Number(
                (quote.construction_pm_buffer * 1 +
                  quote.design_ame_buffer * 1 +
                  Number(quote.engineering_fee_price)) *
                  quote.sqft
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
              }) +
              ""
            }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td class="child-label border-child" colspan="2">
            {{ $t("quote.designArchitectural", "en") }}
          </b-td>
          <b-td class="row-label border-child child-line" colspan="3" />
          <b-td class="border-child" colspan="2" />
        </b-tr>
        <b-tr>
          <b-td class="child-label border-child" colspan="2">
            {{ $t("quote.constructionProjectManagement", "en") }}
          </b-td>
          <b-td class="row-label border-child child-line" colspan="3" />
          <b-td class="border-child" colspan="2" />
        </b-tr>
        <b-tr class="border-group">
          <b-td class="child-label" colspan="2">
            {{ $t("quote.engineeringFee", "en") }}
          </b-td>
          <b-td class="row-label child-line" colspan="3" />
          <b-td colspan="2" />
        </b-tr>
        <b-tr>
          <b-td class="row-label" colspan="2">
            {{ $t("quote.permitFees", "en") }}
          </b-td>
          <b-td class="row-label text-right" colspan="3"
            >{{
              "$" +
              Number(quote.permit_fees_cost_per_sqft).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
              }) +
              ""
            }}
          </b-td>
          <b-td colspan="2" class=" text-right"
            >{{
              "$" +
              Number(quote.permit_fees).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
              }) +
              ""
            }}
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <b-tr class="border-footer">
          <b-td class="row-label" colspan="2">
            {{ $t("quote.turnkeySuiteSubtotal", "en") }}
          </b-td>
          <b-td class="row-label text-right" colspan="3"
            >{{
              "$" +
              Number(
                quote.construction_cost_per_sqft +
                  quote.site_conditions_construction_price_per_sqft +
                  quote.options_construction_price_per_sqft +
                  quote.construction_buffer * 1 +
                  Number(
                    (!quote.ffe_toggle ? 0 : quote.ffe_price_per_sqft) +
                      quote.options_ffe_price_per_sqft +
                      Number(
                        (!quote.avit_toggle ? 0 : quote.avit_price_per_sqft) +
                          quote.options_avit_price_per_sqft
                      ) +
                      Number(
                        quote.construction_pm_buffer * 1 +
                          quote.design_ame_buffer * 1 +
                          Number(quote.engineering_fee_price) * 1
                      ) +
                      Number(quote.permit_fees_cost_per_sqft)
                  )
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
              }) +
              ""
            }}
          </b-td>
          <b-td colspan="2" class=" text-right"
            >{{
              "$" +
              Number(
                Number(
                  quote.construction_pm_buffer * 1 +
                    quote.design_ame_buffer * 1 +
                    Number(quote.engineering_fee_price) * 1
                ) *
                  quote.sqft +
                  Number(quote.permit_fees) +
                  Number((!quote.ffe_toggle ? 0 : quote.ffe_calc) + optionsFfeSubtotal) +
                  Number(
                    (!quote.avit_toggle ? 0 : quote.avit_calc) + optionsAvItSubtotal
                  ) +
                  Number(
                    quote.construction_buffer * 1 * quote.sqft +
                      siteConditionsConstructionSubtotal +
                      optionsConstructionSubtotal +
                      quote.construction_calc
                  )
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
              }) +
              ""
            }}
          </b-td>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
    <div
      v-if="
        Number(Math.abs(quote.site_conditions_price_per_sqft)) <= 0 &&
        Number(Math.abs(quote.options_price_per_sqft)) <= 0
      "
      class="mt-3"
    >
      <hr class="invoice-spacing" style="border: 2px solid #82868b" />
      <b-table-simple
        fixed
        style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
        table-class="text-nowrap"
      >
        <b-tfoot>
          <b-tr>
            <b-td
              class="row-label turnkey-subtotal-primary"
              colspan="5"
              style="border-top: none"
            />
            <b-td
              class="row-label project-total-label"
              colspan="4"
              style="text-align: right; border-top: none"
            >
              {{ $t("quote.projectTotal", "en") }}
            </b-td>
            <b-td
              class="row-label project-total-number text-right"
              colspan="2"
              style="border-top: none"
              >{{
                "$" +
                Number(
                  quote.construction_cost_per_sqft +
                    quote.site_conditions_construction_price_per_sqft +
                    quote.options_construction_price_per_sqft +
                    quote.construction_buffer * 1 +
                    Number(
                      (!quote.ffe_toggle ? 0 : quote.ffe_price_per_sqft) +
                        quote.options_ffe_price_per_sqft +
                        Number(
                          (!quote.avit_toggle ? 0 : quote.avit_price_per_sqft) +
                            quote.options_avit_price_per_sqft
                        ) +
                        Number(
                          quote.construction_pm_buffer * 1 +
                            quote.design_ame_buffer * 1 +
                            Number(quote.engineering_fee_price) * 1
                        ) +
                        Number(quote.permit_fees_cost_per_sqft)
                    ) +
                    Number(quote.site_conditions_price_per_sqft * 1) +
                    Number(quote.options_price_per_sqft * 1)
                ).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                }) +
                ""
              }}&nbsp;
            </b-td>
            <b-td class="project-total-number text-right" colspan="2" style="border-top: none">
              {{
                "$" +
                Number(
                  Number(
                    quote.construction_buffer * 1 * quote.sqft +
                      siteConditionsConstructionSubtotal +
                      optionsConstructionSubtotal +
                      quote.construction_calc
                  ) +
                    Number(
                      (!quote.ffe_toggle ? 0 : quote.ffe_calc) + optionsFfeSubtotal
                    ) +
                    Number(
                      (!quote.avit_toggle ? 0 : quote.avit_calc) + optionsAvItSubtotal
                    ) +
                    Number(
                      (quote.construction_pm_buffer * 1 +
                        quote.design_ame_buffer * 1 +
                        Number(quote.engineering_fee_price) * 1) *
                        quote.sqft
                    ) +
                    Number(quote.permit_fees) +
                    Number(quote.options_total_price) +
                    Number(quote.site_conditions_total_price)
                ).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                }) +
                ""
              }} &nbsp;&nbsp;&nbsp;&nbsp;
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import {
  BCardBody,
  BCol,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
export default {
  props: {
    quote: {
      type: Object,
      default: [],
    },
    montrealAddress: {
      type: String,
      default: [],
    },
    siteConditionsConstructionSubtotal: {
      type: Number,
      default: 0,
    },

    optionsConstructionSubtotal: {
      type: Number,
      default: 0,
    },
    optionsFfeSubtotal: {
      type: Number,
      default: 0,
    },
    optionsAvItSubtotal: {
      type: Number,
      default: 0,
    },
  },
  components: {
    BCardBody,
    BCol,
    BRow,
    BTableSimple,
    BTbody,
    BTd,
    BTfoot,
    BTh,
    BThead,
    BTr,
    Logo,
  },
};
</script>

<style lang="scss" scoped>
th {
  text-align: left !important;
}

.comment-label {
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.caption-table {
  /* Heading/H4 */

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  padding-left: 40px;
  color: #5e5873;
}

.row-label {
  /* Body/Paragraph Semi Bold */

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  /* identical to box height, or 150% */

  align-items: center;

  /* Typography/Body */

  color: #6e6b7b;
}

.border-group {
  border-bottom: 2px solid #d8d6de;
}

.border-group-details {
  border-bottom: 1px solid rgba(31, 41, 47, 0.05);
}

.border-child {
  border-bottom: 2px solid white;
}

.child-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.border-footer {
  border-top: 2px solid rgba(50, 61, 76, 1);
}

.invoice-numbercs {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
}

.header-label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.header-label-grey {
  /* Table/Table Header */

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height */

  text-align: right;
  letter-spacing: 1px;
  text-transform: uppercase;

  /* Typography/Muted & Placeholder */

  color: #b9b9c3;
}

.header-subtotal {
  font-style: normal;
  font-weight: 600;
  font-size: 11.9px;
  line-height: 12px;
  /* identical to box height, or 100% */

  text-align: right;
  letter-spacing: 0.14px;

  /* Typography/Body */

  color: #6e6b7b;
}

.project-sqft {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  /* Typography/Heading & Display */

  color: #5e5873;
}

.project-sqft-total {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-align: end;

  /* Theme Colour / Primary */

  color: rgba(50, 61, 76, 1);
}

.width-100p {
  width: 100%;
}

.child-line {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
}

.project-total-label {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #5e5873;
}

.project-total-number {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: rgba(50, 61, 76, 1);
}

.op-sc-details {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
}

.disclaimer {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
}
.confidential-line {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
  text-transform: uppercase;
}

.credit {
  color: rgba(92, 177, 231, 1);
}

.section-description {
  color: #6e6b7b;
  font-size: 12px;
  font-weight: normal;
}

.base-paragraph {
  font-size: 12px;
}
.dpm-toggles {
  padding-top: 21px;
}
.text_summary {
  color: #6e6b7b;
  font-weight: 400;
}
</style>
