<template>
  <b-card class="invoice-preview-card pagebreak" no-body>
    <b-card-body class="invoice-padding pb-0">
      <div
        class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
      >
        <!-- Header: Left Content -->
        <div />

        <!-- Header: Right Content -->
        <div class="mt-md-0 mt-2">
          <h4 class="invoice-numbercs mb-2">Estimate #{{ quote.tfr.tfr }}</h4>
        </div>
      </div>
      <h2 class="font-weight-bold">Soft Costs</h2>
      <h5 class="text-muted mt-1 mb-0">Summary</h5>
      <div class="pb-4 mt-2">
        <b-table-simple
          fixed
          class="mt-0 pt-0"
          style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
        >
          <b-thead>
            <b-tr>
              <b-th class="table-background-color" colspan="9"> Services</b-th>
              <b-th class="table-background-color text-right" colspan="2">
                Price / SQFT
              </b-th>
              <b-th class="table-background-color text-right" colspan="2">
                Subtotal
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr class="table_sub_header">
              <b-td colspan="9"><b>Base Contract</b></b-td>
              <b-td colspan="2" class="text-right">
                <b>{{
                  Number(
                    quote.construction_pm_buffer * 1 +
                      quote.design_ame_buffer * 1 +
                      Number(quote.engineering_fee_price) +
                      +Number(Number(quote.permit_fees) / Number(quote.sqft))
                  ).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}</b></b-td
              >
              <b-td colspan="2" class="text-right"
                ><b>{{
                  Number(
                    (quote.construction_pm_buffer * 1 +
                      quote.design_ame_buffer * 1 +
                      Number(quote.engineering_fee_price)) *
                      quote.sqft +
                      quote.permit_fees
                  ).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}</b></b-td
              >
            </b-tr>
            <b-tr>
              <template>
                <b-td colspan="9">
                  <div class="ml-2">Design & Architecture</div>
                </b-td>
                <b-td colspan="2" class="text-muted text-right">
                  {{
                    Number(quote.design_ame_buffer).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>

                <b-td colspan="2" class="text-muted text-right">
                  {{
                    Number(quote.design_ame_buffer * quote.sqft).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>
              </template>
            </b-tr>
            <b-tr>
              <template>
                <b-td colspan="9">
                  <div class="ml-2">Engineering</div>
                </b-td>
                <b-td colspan="2" class="text-muted text-right">
                  {{
                    Number(quote.engineering_fee_price).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>

                <b-td colspan="2" class="text-muted text-right">
                  {{
                    Number(quote.engineering_fee_price * quote.sqft).toLocaleString(
                      "en-CA",
                      {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      }
                    )
                  }}
                </b-td>
              </template>
            </b-tr>
            <b-tr>
              <template>
                <b-td colspan="9">
                  <div class="ml-2">Project Management</div>
                </b-td>
                <b-td colspan="2" class="text-muted text-right">
                  {{
                    Number(quote.construction_pm_buffer * 1).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>

                <b-td colspan="2" class="text-muted text-right">
                  {{
                    Number(quote.construction_pm_buffer * 1 * quote.sqft).toLocaleString(
                      "en-CA",
                      {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      }
                    )
                  }}
                </b-td>
              </template>
            </b-tr>
            <b-tr>
              <template>
                <b-td colspan="9">
                  <div class="ml-2">Permits</div>
                </b-td>
                <b-td colspan="2" class="text-muted text-right">
                  {{
                    Number(quote.permit_fees / quote.sqft).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>

                <b-td colspan="2" class="text-muted text-right">
                  {{
                    Number(quote.permit_fees).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>
              </template>
            </b-tr>
          </b-tbody>
          <b-tbody v-if="scItems.length > 0">
            <b-tr class="table_sub_header">
              <b-td colspan="9"><b>Base Contract Additions</b></b-td>
              <b-td colspan="2" class="text-right">
                <b>
                  {{
                    Number(scSubtotal / quote.sqft).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}</b
                ></b-td
              >
              <b-td colspan="2" class="text-right"
                ><b>{{
                  Number(scSubtotal).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}</b></b-td
              >
            </b-tr>
            <b-tr v-for="(sc, index) in scItems" :key="index">
              <template>
                <b-td colspan="9">
                  <div class="ml-2">
                    {{ sc.name }}
                    <p class="text-muted">
                      {{ sc.details }}
                    </p>
                  </div>
                </b-td>
                <b-td colspan="2" class="text-muted text-right">
                  {{
                    Number(sc.total_price / quote.sqft).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>

                <b-td colspan="2" class="text-muted text-right">
                  {{
                    Number(sc.total_price).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>
              </template>
            </b-tr>
          </b-tbody>
          <b-tbody v-if="allowItems.length > 0">
            <b-tr class="table_sub_header">
              <b-td colspan="9"><b>Cash Allowances</b></b-td>
              <b-td colspan="2" class="text-right">
                <b>{{
                  Number(allowSubtotal / quote.sqft).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}</b></b-td
              >
              <b-td colspan="2" class="text-right"
                ><b>{{
                  Number(allowSubtotal).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}</b></b-td
              >
            </b-tr>
            <b-tr v-for="(a, index) in allowItems" :key="index">
              <template>
                <b-td colspan="9">
                  <div class="ml-2">
                    {{ a.name }}
                    <p class="text-muted">
                      {{ a.details }}
                    </p>
                  </div>
                </b-td>
                <b-td colspan="2" class="text-muted text-right">
                  {{
                    Number(a.total_price / quote.sqft).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>

                <b-td colspan="2" class="text-muted text-right">
                  {{
                    Number(a.total_price).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>
              </template>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <hr
          class="invoice-spacing mt-2"
          style="border: 1px solid rgba(50, 61, 76, 1)"
        />
        <b-table-simple
          fixed
          style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
          table-class="text-nowrap"
        >
          <b-tfoot>
            <b-tr>
              <b-td
                class="row-label project-total-label"
                colspan="9"
                style="border-top: none"
              >
                <h4 style="font-weight: 600">Soft Costs Subtotal</h4>
              </b-td>
              <b-td
                class="row-label project-total-number text-right"
                colspan="2"
                style="border-top: none"
              >
                {{
                  Number(
                    quote.construction_pm_buffer * 1 +
                      quote.design_ame_buffer * 1 +
                      Number(quote.engineering_fee_price) +
                      Number(Number(quote.permit_fees) / Number(quote.sqft)) +
                      (Number(allowSubtotal) + Number(scSubtotal)) / quote.sqft
                  ).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </b-td>
              <b-td
                class="project-total-number text-right"
                colspan="2"
                style="border-top: none"
              >
                {{
                  Number(
                    (quote.construction_pm_buffer * 1 +
                      quote.design_ame_buffer * 1 +
                      Number(quote.engineering_fee_price)) *
                      quote.sqft +
                      quote.permit_fees +
                      Number(allowSubtotal) +
                      Number(scSubtotal)
                  ).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </div>
    </b-card-body>
    <b-card-body class="invoice-padding notprintable pt-0 footer-quote my-3">
      <span class="confidential-line float-right">{{
        $t("quote.confidential", "en")
      }}</span>
    </b-card-body>
    <hr class="bg-primary p-0 m-0 hr_bottom" style="height: 8px" />
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCol,
  BButton,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
  VBToggle,
} from "bootstrap-vue";

export default {
  name: "ReviewConstructionSpecSection",
  components: {
    BCard,
    BCardBody,
    BCol,
    BRow,
    BTableSimple,
    BButton,
    BTr,
    BTh,
    BThead,
    BTfoot,
    BTd,
    BTbody,
  },
  props: ["quote"],
  computed: {
    scSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "Soft Cost")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    scItems() {
      let result = this.quote.options.filter((c) => c.budget_group == "Soft Cost");
      return result;
    },
    allowItems() {
      let result = this.quote.allowances.filter((c) => c.budget_group == "Soft Cost");
      return result;
    },
    allowSubtotal() {
      let result = this.quote.allowances
        .filter((c) => c.budget_group == "Soft Cost")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
  },
};
</script>

<style scoped>
.disclaimer-exclusions {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ea5455;
}

.card-body {
  padding: 28px;
}
.hide-lines-review {
  display: none !important;
}
@media print {
  .pagebreak-table {
    page-break-after: always;
    white-space: nowrap;
    padding-top: 2em !important;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  .hide-lines {
    display: none !important;
  }
  .hide-lines-review {
    display: table !important;
    padding-top: 2em !important;
  }
}
</style>
