<template>
  <div v-if="tfr.test_fit_url !==null">
    <b-card v-if="tfr_is_printing || isPrintMode" class="invoice-preview-card pagebreak" no-body>
      <div style="height: 100vh; width: 100vh;">
        <img :src="tfr.test_fit_url" alt="" class="Image"
             :class="tfr.test_fit_orientation!=='portrait'?'rotate':'no-rotate'"
        >
      </div>
      <div class="hide_confidential"></div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardBody } from 'bootstrap-vue'
import planeImage from './SSPlane.png'
import horizontalPlane from './horizontalView.png'

export default {
  props: {
    tfr: {
      type: Object,
    },
    tfr_is_printing:{
      boolean: false,
    },
  },
  components: {
    BCardBody,
    BCard,
  },
  data() {
    return {
      image: horizontalPlane,
      isPrintMode: false,
      newPage: null, // Reference to the dynamically added page
      isLandscapeImage: false,
    }
  },
  mounted() {
    window.addEventListener('beforeprint', this.handleBeforePrint)
    window.addEventListener('afterprint', this.handleAfterPrint)
  },
  beforeDestroy() {
    window.removeEventListener('beforeprint', this.handleBeforePrint)
    window.removeEventListener('afterprint', this.handleAfterPrint)
  },
  methods: {
    handleBeforePrint() {
      this.isPrintMode = true
    },
    handleAfterPrint() {
      this.isPrintMode = false // Remove the dynamically added page after printing
    },
  },
}
</script>

<style lang="scss" scoped>
.page {
  page-break-after: always;
  /* Additional styles for the pages */
}

@page {
  size: portrait;
}

@media print {
  .printContent {
    visibility: hidden !important;
  }
  .rotate {
    transform: rotate(90deg);
    width: 120vw !important;
    height: auto;
    top: 17vh !important;
    position: absolute;
    left: -10vh;
  }
  .no-rotate {
    //transform: rotate(90deg);
    max-width: 75vh !important;
    margin-left: -1vh;
    height: auto;
  }
  .hide_confidential {
    background: white;
    position: absolute;
    height: 70px;
    width: 110vh;
    z-index: 10;
    bottom: 50px;
    left: -30px;
  }
}
</style>
