<template>
  <section class="invoice-preview-wrapper">
    <div id="lottie" v-show="show">
      <div class="loading-logo">
        <img style="margin-left: -4rem;"  alt="Logo" src="/logo-transparent.png" />
      </div>
      <div class="loading">
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>
    <template v-if="quote.id">
      <b-row class="pb-1 notprintable">
        <b-col cols="7" style="font-weight: 600; font-size: 11.9px; line-height: 12px">
          <quote-info-header :quote="quote" />
          <!-- <h2 v-if="quote.id" class="notprintable" style="font-weight: 700 !important; font-size: 28px!important; line-height: 34px;!important">
          {{ quote.opportunity.address + ', ' + quote.opportunity.geography }}
        </h2>
        <uil-suitcase
          class="logo"
          size="15px"
        />
          {{ quote.opportunity.account_name }}
        <span style="padding-left:20px">
        <uil-tape
          class="logo"
          size="15px"
        />
          {{ quote.opportunity.rentable_sqft + ' sqft' }}
        </span>
        <span style="padding-left:20px">
          <uil-bill
            class="logo"
            size="15px"
          />
            {{ quote.tfr_name }}
        </span>
        <b-badge
          pill
          variant="primary"
          style="height: 20.4px;background-color: rgba(32, 201, 151, 0.12);color: #20C997; margin-right:25px;margin-left:45px"
          v-if="quote.submitted_for_handover == true"
        >Active</b-badge>
        <span style="padding-left:20px; font-weight: 400;color: #B9B9C3;">
          {{ "DC Version " + quote.base_spec_version }}
        </span>
        <p class="pt-1">
          <b-badge
            pill
            :class="badgeClass(quote.tfr.tfr_status)"
          >
            {{quote.tfr.tfr_status}}
          </b-badge>
        </p> -->
        </b-col>
        <b-col cols="5" style="text-align: end; align-self: self-end">
          <b-button
            class="mr-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="$bvModal.show('completeQuote')"
            v-if="quote.tfr.tfr_status == 'Reviewing Quote'"
          >
            <uil-lock class="logo" size="15" /> Complete Quote
          </b-button>
          <b-button
            class="mr-1"
            variant="primary"
            v-if="quote.tfr.tfr_status == 'Quote in Progress'"
            @click="$bvModal.show('requestSalesReview')"
          >
            Send for Sales Review
          </b-button>
          <b-button
            variant="outline-secondary"
            class="btn-icon mr-1"
            style="width: 38px; height: 38px"
            :href="
              isOldView
                ? `/opportunities/${this.$route.params.opportunityid}/${quote.id}/edit`
                : quote.is_itemized
                ? `/opportunities/${this.$route.params.opportunityid}/${quote.id}/itemized-edit`
                : `/opportunities/${this.$route.params.opportunityid}/${quote.id}/n-edit`
            "
          >
            <uil-edit-alt class="logo" size="15" />
          </b-button>
          <b-dropdown
            no-caret
            toggle-class="rounded-square p-0 "
            style="width: 38px; height: 38px"
            variant="outline-secondary"
            offset="-175"
          >
            <template #button-content>
              <feather-icon class="btn-icon" icon="MoreVerticalIcon" size="15" />
            </template>
            <b-dropdown-item
              v-if="!quote.is_itemized"
              target="_blank"
              :href="`/opportunities/${this.$route.params.opportunityid}/compare-quotes/${quote.id}`"
            >
              <uil-chart-line class="logo" size="15" />
              Compare Quote
            </b-dropdown-item>
            <b-dropdown-item @click="printInvoice">
              <uil-import class="logo" size="15" /> Save as PDF
            </b-dropdown-item>
            <b-dropdown-item
              v-if="quote.tfr.tfr_status != 'Archived'"
              @click="$bvModal.show('archiveQuote')"
              :disabled="quote.tfr.marked_as_active"
            >
              <uil-archive class="logo" size="15" /> Archive Revision
            </b-dropdown-item>
            <b-dropdown-divider
              v-if="isAdmin != 1 && !quote.submitted_for_handover"
            ></b-dropdown-divider>
            <b-dropdown-divider v-else-if="isAdmin == 1"></b-dropdown-divider>
            <b-dropdown-item
              v-if="isAdmin != 1 && !quote.submitted_for_handover"
              :disabled="
                quote.tfr.tfr_status != 'Reviewing Quote' &&
                quote.tfr.tfr_status != 'Quote Completed'
              "
              @click="revertQuoteInProgress()"
            >
              <uil-sync class="logo" size="15" />
              Revert to Quote In Progress
            </b-dropdown-item>
            <b-dropdown-item
              v-else-if="isAdmin == 1"
              :disabled="
                quote.tfr.tfr_status != 'Reviewing Quote' &&
                quote.tfr.tfr_status != 'Quote Completed'
              "
              @click="revertQuoteInProgress()"
            >
              <uil-sync class="logo" size="15" />
              Revert to Quote In Progress
            </b-dropdown-item>
            <b-dropdown-item
              v-if="quote.tfr.tfr_status == 'Archived' && isAdmin == 1"
              @click="unarchiveRevision(quote.tfr.tfr_id)"
              :disabled="quote.tfr.tfr_status != 'Archived'"
            >
              <uil-archive class="logo" size="15" />
              <span class="align-middle ml-50">Unarchive Revision</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <b-row class="invoice-preview">
        <!-- Col: Left (Invoice Container) -->
        <b-col cols="12" md="8" xl="9">
          <!-- <b-tabs>
            <b-tab title="Client View" class="printable">

            </b-tab> -->
          <!-- <b-tab title="Internal Output" class="notprintable">
              <dpm-output
                :quote="quote"
                :optionsAvItSubtotal="optionsAvItSubtotal"
                :optionsFfeSubtotal="optionsFfeSubtotal"
                :optionsConstructionSubtotal="optionsConstructionSubtotal"
                :siteConditionsConstructionSubtotal="siteConditionsConstructionSubtotal"
                :siteConditions="siteConditions"
                :options="options"
              />
            </b-tab> -->
          <!-- <template #tabs-end class="notprintable">
            <b-nav-item :href="quote.tfr.test_fit_pdf" target="_blank" role="presentation" :disabled="quote.tfr.test_fit_pdf == null" ><feather-icon icon="ExternalLinkIcon" />View Test Fit</b-nav-item>
          </template> -->
          <!-- </b-tabs> -->
          <div v-if="!quote.is_itemized" class="mt-2">
            <div v-if="isOldView">
              <b-card class="invoice-preview-card" no-body>
                <o-summary
                  :quote="quote"
                  :montrealAddress="montrealAddress"
                  :optionsAvItSubtotal="optionsAvItSubtotal"
                  :optionsFfeSubtotal="optionsFfeSubtotal"
                  :optionsConstructionSubtotal="optionsConstructionSubtotal"
                  :siteConditionsConstructionSubtotal="siteConditionsConstructionSubtotal"
                  :showOptions="showOptions"
                />
                <b-card-body class="invoice-padding pt-0 footer-quote my-3">
                  <span class="confidential-line float-right">{{
                    $t("quote.confidential", "en")
                  }}</span>

                  <div class="page_botton_margin"></div>
                </b-card-body>
                <hr class="bg-primary p-0 m-0 hr_bottom" style="height: 8px" />
              </b-card>
              <o-base-contract
                :quote="quote"
                :options="options"
                :optionsAvItSubtotal="optionsAvItSubtotal"
                :optionsConstructionSubtotal="optionsConstructionSubtotal"
                :showOptions="showOptions"
                :showSiteConditions="showSiteConditions"
                :siteConditions="siteConditions"
                :siteConditionsConstructionSubtotal="siteConditionsConstructionSubtotal"
                :optionsFfeSubtotal="optionsFfeSubtotal"
              />

              <assumptions-exclusions :quote="quote" />
              <construction-spec :quote="quote" />
              <ffe-spec
                :quote="quote"
                v-if="quote.ffe_toggle && quote.ffe_spec_quote.length > 0"
              />

              <avit-spec :quote="quote" v-if="quote.avit_toggle" />
            </div>
            <div v-else>
              <PlaneImage :tfr="quote.tfr" :tfr_is_printing="tfr_is_printing" />
              <b-card class="invoice-preview-card" no-body>
                <n-summary
                  :quote="quote"
                  :montrealAddress="montrealAddress"
                  :optionsAvItSubtotal="optionsAvItSubtotal"
                  :optionsFfeSubtotal="optionsFfeSubtotal"
                  :optionsConstructionSubtotal="optionsConstructionSubtotal"
                  :siteConditionsConstructionSubtotal="siteConditionsConstructionSubtotal"
                  :showOptions="showOptions"
                />
                <b-card-body class="invoice-padding pt-0 footer-quote my-3">
                  <span class="confidential-line float-right">{{
                    $t("quote.confidential", "en")
                  }}</span>
                  <div class="page_botton_margin"></div>
                </b-card-body>
                <hr class="bg-primary p-0 m-0 hr_bottom" style="height: 8px" />
              </b-card>
              <n-base-contract
                :quote="quote"
                :options="options"
                :optionsAvItSubtotal="optionsAvItSubtotal"
                :optionsConstructionSubtotal="optionsConstructionSubtotal"
                :showOptions="showOptions"
                :showSiteConditions="showSiteConditions"
                :siteConditions="siteConditions"
                :siteConditionsConstructionSubtotal="siteConditionsConstructionSubtotal"
                :optionsFfeSubtotal="optionsFfeSubtotal"
              />
              <n-cstrn-spec :quote="quote" />
              <n-ffe-spec :quote="quote" />
              <n-av-it-spec :quote="quote" />
              <n-soft-cost :quote="quote" />
              <n-dpm-scope-page
                :quote="quote"
                v-if="Number(quote.base_spec_version) >= 3.2"
              />
              <n-assumptions :quote="quote" />
            </div>
          </div>
          <div v-else class="mt-2">
            <b-card class="invoice-preview-card" no-body>
              <itemized-quote
                :quote="quote"
                :montrealAddress="montrealAddress"
                :optionsAvItSubtotal="optionsAvItSubtotal"
                :optionsFfeSubtotal="optionsFfeSubtotal"
                :optionsConstructionSubtotal="optionsConstructionSubtotal"
                :siteConditionsConstructionSubtotal="siteConditionsConstructionSubtotal"
                :showOptions="showOptions" />
              <hr class="bg-primary p-0 m-0 hr_bottom" style="height: 8px"
            /></b-card>
          </div>
        </b-col>

        <!-- Right Col: Card -->
        <b-col class="invoice-actions" cols="12" md="4" xl="3">
          <!-- <b-card>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            class="mb-75"
            variant="primary"
            @click="printInvoice"
          >
            Save as PDF
          </b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            :href="`/opportunities/${this.$route.params.opportunityid}/${quote.id}/edit`"
            block
            class="mb-75"
            variant="outline-secondary"
          >
            Edit
          </b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            :href="`/opportunities/${this.$route.params.opportunityid}/compare-quotes/${quote.id}`"
            block
            class="mb-75"
            variant="outline-secondary"
          >
            Compare Quote
          </b-button>

        </b-card> -->

          <!-- <b-card v-if="quote.opportunity.submitted_for_handover">
            <b-row>
              <b-col cols="12">
                <div class="d-flex">
                  <p
                    class="mb-0"
                    style="
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 21px;
                      color: #6e6b7b;
                    "
                  >
                    Active Quote
                  </p>
                  <label for="switch_1" class="ml-auto mb-0"></label>
                  <b-form-checkbox
                    v-model="quote.marked_as_active"
                    :checked="false"
                    switch
                    class="ml-2 mr-n2"
                    @change="updateActiveStatus()"
                    :disabled="quote.opportunity.ccdc_signed == true"
                  ></b-form-checkbox>
                </div>
              </b-col>
            </b-row>
          </b-card> -->

          <comment-section
            :comments="comments"
            :demolition-image="quote.demolition_image"
            :quote-id="quoteId"
          />
        </b-col>
      </b-row>
    </template>
    <b-modal
      id="completeQuote"
      ref="completeQuote"
      title="Complete Quote"
      :centered="true"
      body-class="modal-padding"
      :no-close-on-backdrop="true"
    >
      Marking this quote as complete will lock this quote. It can still be viewed, and
      marked as active but can no longer be edited.
      <template #modal-footer="{ cancel }">
        <b-button variant="outline-secondary" @click="cancel()" :disabled="modalIsBusy">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button variant="primary" @click="markCompleteQuote()" :disabled="modalIsBusy">
          Complete Quote
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="requestSalesReview"
      ref="requestSalesReview"
      title="Send for Sales Review"
      :centered="true"
      body-class="modal-padding"
      :no-close-on-backdrop="true"
    >
      This will notify sales to review the quote before sending it to a client.
      <validation-observer ref="requestSalesReviewRef" tag="form">
        <form ref="requestSalesReviewForm">
          <validation-provider
            #default="{ errors }"
            name="Estimator notes"
            rules="required"
          >
            <b-form-group class="pt-1">
              <label for="InputHelp">Estimator Notes</label>
              <b-form-textarea
                id="item-description"
                placeholder="Add notes for Sales Review"
                rows="3"
                v-model="quote.tfr.estimator_notes"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </form>
      </validation-observer>
      <template #modal-footer="{ cancel }">
        <b-button variant="outline-secondary" @click="cancel()" :disabled="modalIsBusy">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button variant="primary" @click="requestSalesReview()" :disabled="modalIsBusy">
          Send for Review
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="archiveQuote"
      ref="archiveQuote"
      title="Archive Revision"
      @ok="archiveRevision"
      ok-title="Archive"
      cancel-title="Go Back"
      cancel-variant="outline-secondary"
      :centered="true"
      body-class="modal-padding"
      :no-close-on-backdrop="true"
    >
      An archived quote will still be available to view but can no longer be edited.
    </b-modal>
  </section>
</template>

<script>
import {
  UilBill,
  UilBox,
  UilCheckCircle,
  UilEditAlt,
  UilExclamationTriangle,
  UilHardHat,
  UilPlus,
  UilRuler,
  UilSuitcase,
  UilTape,
  UilWifi,
  UilSetting,
  UilChartLine,
  UilImport,
  UilSync,
  UilArchive,
  UilLock,
} from "@iconscout/vue-unicons";
import {
  BButton,
  BCard,
  BCardBody,
  BCol,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
  VBToggle,
  BTabs,
  BTab,
  BNavItem,
  BBadge,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormTextarea,
  BDropdownDivider,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CommentSection from "../Comments/Components/Comment.vue";
import AssumptionsExclusions from "./Components/AssumptionsExclusionsTab.vue";
import ConstructionSpec from "./Components/ConstructionSpecTab.vue";
import FfeSpec from "./Components/FfeSpecTab.vue";
import AvitSpec from "./Components/AvitSpecTab.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItemDPMOutput.vue";
import DpmOutput from "./Components/DpmOutputTab.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import QuoteInfoHeader from "./Components/QuoteInfoHeader.vue";
import useAppConfig from "@core/app-config/useAppConfig";
import { computed } from "@vue/composition-api";
// old quote components
import OSummary from "./Components/OldView/OSummary.vue";
import OBaseContract from "./Components/OldView/OBaseContract.vue";
// new quote components
import NSummary from "./Components/NewView/NSummary.vue";
import NAssumptions from "./Components/NewView/NAssumptions.vue";
import NBaseContract from "./Components/NewView/NBaseContract.vue";
import NBaseContractAdditions from "./Components/NewView/NBaseContractAdditions.vue";
import NCashAllowances from "./Components/NewView/NCashAllowances.vue";
import NFfeSpec from "./Components/NewView/NFfeSpec.vue";
import NAvItSpec from "./Components/NewView/NAvItSpec.vue";
import NCstrnSpec from "./Components/NewView/NCstrnSpec.vue";
import NSoftCost from "./Components/NewView/NSoftCost.vue";
import NDpmScopePage from "./Components/NewView/NDpmScopePage.vue";
import ItemizedQuote from "./Components/ItemizedQuote/ItemizedQuote.vue";
import PlaneImage from "@/views/Quotes/image_test/PlaneImage.vue";
export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    PlaneImage,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableSimple,
    BButton,
    BTr,
    BTh,
    BThead,
    BTfoot,
    BTd,
    BTbody,
    Logo,
    CommentSection,
    AssumptionsExclusions,
    ConstructionSpec,
    FfeSpec,
    AvitSpec,
    BTabs,
    BTab,
    AppCollapse,
    AppCollapseItem,
    DpmOutput,
    UilRuler,
    UilSuitcase,
    UilBill,
    UilTape,
    UilBox,
    UilWifi,
    UilHardHat,
    UilPlus,
    UilCheckCircle,
    UilEditAlt,
    UilExclamationTriangle,
    BNavItem,
    BBadge,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    UilChartLine,
    UilImport,
    UilSync,
    BFormGroup,
    BFormTextarea,
    UilArchive,
    BDropdownDivider,
    UilLock,
    QuoteInfoHeader,
    // old view components
    OSummary,
    OBaseContract,
    // new view components
    NSummary,
    NAssumptions,
    NBaseContract,
    NBaseContractAdditions,
    NCashAllowances,
    NFfeSpec,
    NAvItSpec,
    NCstrnSpec,
    NSoftCost,
    NDpmScopePage,
    ItemizedQuote,
  },
  data() {
    return {
      quoteId: this.$route.params.quoteid,
      quote: {
        opportunity: {},
        comments: {},
      },
      comments: {},
      options: {},
      siteConditions: {},
      show: true,
      newComment: "",
      modalIsBusy: false,
      isAdmin: localStorage.getItem("isAdmin"),
      isOldView: true,
      tfr_is_printing: false,
    };
  },
  beforeMount() {
    this.getQuotes();
  },
  computed: {
    optionsAvItSubtotal() {
      var sum = 0;
      this.options
        .filter((n) => n.budget_group == "AV/IT" && n.is_base_spec == true)
        .forEach((e) => {
          sum += parseFloat(e.total_price);
        });
      return parseFloat(sum);
    },
    optionsFfeSubtotal() {
      var sum = 0;
      this.options
        .filter((n) => n.budget_group == "FF&E" && n.is_base_spec == true)
        .forEach((e) => {
          sum += parseFloat(e.total_price);
        });
      return parseFloat(sum);
    },
    optionsConstructionSubtotal() {
      var sum = 0;
      this.options
        .filter((n) => n.budget_group == "Construction" && n.is_base_spec == true)
        .forEach((e) => {
          sum += parseFloat(e.total_price);
        });
      return parseFloat(sum);
    },
    siteConditionsConstructionSubtotal() {
      var sum = 0;
      this.siteConditions
        .filter((n) => n.budget_group == "Construction" && n.is_base_spec == true)
        .forEach((e) => {
          sum += parseFloat(e.total_price);
        });
      return parseFloat(sum);
    },
    showSiteConditions() {
      var siteconditionsFiltered = [];
      siteconditionsFiltered = this.siteConditions.filter((n) => n.is_base_spec == false);
      if (siteconditionsFiltered.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    showOptions() {
      var optionsFiltered = [];
      optionsFiltered = this.options.filter((n) => n.is_base_spec == false);
      if (optionsFiltered.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    montrealAddress() {
      if (this.quote.language == "fr") {
        return "1325-1010 rue de la Gauchetière Ouest";
      } else {
        return "1325-1010 de la Gauchetière West Street";
      }
    },
  },
  setup() {
    const { skin } = useAppConfig();

    const isDark = computed(() => skin.value === "dark");

    return { skin, isDark };
  },
  methods: {
    tenderExport() {
      this.$http({
        url: `quote/tender-export/${this.quoteId}`,
        method: "GET",
        responseType: "blob",
      }).then((res) => {
        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", `Quote #${this.quote.tfr.tfr}` + ".xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    getQuotes() {
      this.$http
        .get(`/quote/${this.quoteId}`)
        .then((response) => {
          this.quote = response.data;
          this.comments = this.quote.comments;
          this.options = this.quote.options;
          this.siteConditions = this.quote.site_conditions;
          this.show = false;
          if (this.quote.tfr.tfr) {
            document.title = `[${this.quote.tfr.tfr}] View Quote`;
          }
          if (Number(this.quote.base_spec_version >= 3)) {
            this.isOldView = false;
          }
          this.updateBreadcrumb();
        })
        .catch((error) => {
          this.showToast("danger", error.response.data.message);
        });
    },
    updateActiveStatus() {
      if (this.quote.marked_as_active == true) {
        this.$bvModal
          .msgBoxConfirm(
            "Only one quote can be active at a time. Any previous quote marked as active will be set to inactive.",
            {
              title: "Mark Quote as Active",
              hideHeaderClose: true,
              okTitle: "Continue",
              cancelTitle: "Cancel",
            }
          )
          .then((value) => {
            if (value == true) {
              this.$http
                .get(
                  `/opportunities/${this.$route.params.opportunityid}/tfr/${this.quote.tfr_id}/quote/${this.quoteId}/mark-as-active`
                )
                .then((response) => {
                  // console.log(response);
                })
                .catch((error) => {
                  this.showToast("danger", error.response.data.message);
                });
            } else {
              this.quote.marked_as_active = false;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.quote.marked_as_active == false) {
        this.$http
          .get(
            `/opportunities/${this.$route.params.opportunityid}/tfr/${this.quote.tfr_id}/quote/${this.quoteId}/mark-as-active`
          )
          .then((response) => {
            // console.log(response)
          })
          .catch((error) => {
            this.showToast("danger", error.response.data.message);
          });
      }
    },
    updateBreadcrumb() {
      this.$route.meta.breadcrumb[0].text = `${this.quote.opportunity.address}, ${this.quote.opportunity.geography}`;
      if (this.quote.opportunity.marked_as_active) {
        this.$route.meta.breadcrumb[0].to = `/active/${this.$route.params.opportunityid}`;
      } else {
        this.$route.meta.breadcrumb[0].to = `/opportunities/${this.$route.params.opportunityid}`;
      }

      this.$route.meta.breadcrumb[0].active = false;
      this.$route.meta.breadcrumb[2].text = `Quote #${this.quote.tfr.tfr}`;
      this.$root.$emit("rerender-breadcrumb");
    },
    printInvoice() {
      let isDarkMode = this.isDark ? "light" : "dark";
      console.log(isDarkMode);
      // this.skin = this.isDark ? "light" : "dark";
      if (isDarkMode != "dark") {
        this.skin = "light";
      }

      setTimeout(() => {
        document.title =
          "Clearspace - " +
          this.quote.tfr.tfr +
          " - " +
          this.quote.opportunity.account_name +
          " - " +
          this.quote.opportunity.address;
        this.tfr_is_printing = !true
        window.print();
        if (isDarkMode != "dark") {
          this.skin = "dark";
        }
      }, 200);
      this.tfr_is_printing=!false
    },
    initials(text) {
      const initial = text
        .match(/(\b\S)?/g)
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toUpperCase();

      return initial;
    },
    badgeClass(status) {
      if (status == "Awaiting Test Fit") {
        return "awaiting-test-fit";
      } else if (status == "Reviewing Test Fit") {
        return "reviewing-test-fit";
      } else if (status == "Archived") {
        return "archived";
      } else if (status == "Awaiting Quote") {
        return "awaiting-quote";
      } else if (status == "Quote in Progress") {
        return "quote-in-progress";
      } else if (status == "Submitted for Handover" || status == "Active") {
        return "submitted-for-handover";
      } else if (status == "Reviewing Quote") {
        return "reviewing-quote";
      } else if (status == "Sent to Client") {
        return "sent-to-client";
      } else if (status == "Hardened") {
        return "hardened";
      } else if (status == "Quote Completed") {
        return "quote-completed";
      }
    },
    markCompleteQuote() {
      this.modalIsBusy = true;
      const completeQuoteData = {
        estimator_notes: this.quote.tfr.estimator_notes,
        quote_id: this.quote.id,
      };
      this.$http
        .post(
          `/opportunities/${this.$route.params.opportunityid}/tfr/${this.quote.tfr_id}/complete-quote`,
          completeQuoteData
        )
        .then((response) => {
          this.quote.tfr.tfr_status = "Quote Completed";
          this.quote = response.data.data;
          this.comments = this.quote.comments;
          this.options = this.quote.options;
          this.siteConditions = this.quote.site_conditions;
          this.showToast(
            "success",
            "This quote is no longer editable.",
            "Quote Completed"
          );
          this.modalIsBusy = false;
          this.$nextTick(() => {
            //this.$bvModal.hide('attachTestFit')
            this.$refs["completeQuote"].hide();
          });
          resolve(true);
        })
        .catch((error) => {
          this.modalIsBusy = false;
          this.showToast("danger", error.response.data.message);
        });
    },
    revertQuoteInProgress() {
      this.modalIsBusy = true;
      const revertQuoteData = {
        quote_id: this.quote.id,
      };
      this.$http
        .post(
          `/opportunities/${this.$route.params.opportunityid}/tfr/${this.quote.tfr_id}/revert-to-quote-in-progress`,
          revertQuoteData
        )
        .then((response) => {
          this.quote.tfr.tfr_status = "Quote in Progress";
          this.modalIsBusy = false;
          this.showToast(
            "success",
            "Quote status reverted to In Progress.",
            "Quote In Progress"
          );
        })
        .catch((error) => {
          this.modalIsBusy = false;
          this.showToast(
            "danger",
            "Contact Digital for support.",
            "Something went wrong"
          );
        });
    },
    archiveRevision() {
      this.$http
        .post(
          `/opportunities/${this.$route.params.opportunityid}/tfr/${this.quote.tfr_id}/revise-test-fit`
        )
        .then((response) => {
          this.quote.tfr.tfr_status = "Archived";
          this.showToast(
            "success",
            "The revision will still be available to view but can no longer be edited.",
            "Revision Archived"
          );
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "Contact Digital for support.",
            "Something went wrong"
          );
        });
    },
    requestSalesReview() {
      return new Promise((resolve, reject) => {
        this.$refs.requestSalesReviewRef.validate().then((success) => {
          const completeQuoteData = {
            estimator_notes: this.quote.tfr.estimator_notes,
            quote_id: this.quote.id,
          };
          if (success) {
            this.modalIsBusy = true;
            this.$http
              .post(
                `/opportunities/${this.$route.params.opportunityid}/tfr/${this.quote.tfr_id}/request-sales-review`,
                completeQuoteData
              )
              .then((response) => {
                this.quote.tfr.tfr_status = "Reviewing Quote";
                this.quote = response.data.data;
                this.comments = this.quote.comments;
                this.options = this.quote.options;
                this.siteConditions = this.quote.site_conditions;
                this.showToast(
                  "success",
                  "Sales will be notified to review.",
                  "Sent for Review"
                );
                this.$nextTick(() => {
                  //this.$bvModal.hide('attachTestFit')
                  this.$refs["requestSalesReview"].hide();
                });
                this.modalIsBusy = false;
                resolve(true);
              })
              .catch((error) => {
                this.showToast("danger", error.response.data.message);
              });
          } else {
            reject();
          }
        });
      });
    },

    unarchiveRevision() {
      this.tableIsBusy = true;
      this.$http
        .get(
          `/opportunities/${this.$route.params.opportunityid}/tfr/${this.quote.tfr_id}/unarchive`
        )
        .then((response) => {
          this.tableIsBusy = false;
          this.quote.tfr = response.data.data;
          this.showToast("success", "Revision unarchived and reverted", "Unarchived");
        })
        .catch((error) => {
          console.log(error);
          this.modalIsBusy = false;
          this.showToast(
            "danger",
            "Contact Digital for support.",
            "Something went wrong"
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
<style lang="scss">
@media print {
  html {
    overflow: visible !important;
  }
  // Global Styles
  body {
    background-color: transparent !important;
    height: 100%;
    margin: 0px;
  }
  .page {
    page-break-after: always;
    /* Additional styles for the pages */
  }

  .landscape {
    width: 297mm;
    height: 210mm;
    /* Additional styles for landscape pages */
  }
  @page {
    size: portrait;
  }

  @page landscape {
    size: landscape;
  }

  .landscape {
    page: landscape;
    width: 100%;
    height: 100%;
  }

  .invoice_header {
    margin-left: -30px !important;
    margin-top: -60px !important;
    padding: 0 30px !important;
  }
  .footer-quote {
    position: fixed;
    bottom: -20px;
    width: 95%;

    .page_botton_margin {
      position: fixed;
      bottom: 0px !important;
      border-bottom: rgba(92, 177, 231, 1) solid 8px !important;
      width: 140%;
      padding: 20px;
      margin-left: -40px;
    }
  }

  .hr_bottom {
    display: none;
  }
  .pagebreak {
    page-break-before: always;
  }
  .printable {
    display: block !important;
  }
  .notprintable {
    display: none;
  }

  .breadcrumbs-top {
    display: none;
  }

  nav.header-navbar {
    display: none;
  }
  .nav.nav-tabs {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
    position: relative !important;
    width: 1192px !important;
  }

  .customizer-toggle {
    display: none !important;
  }
  [dir] .table td,
  [dir] .table th {
    background-color: transparent !important;
  }
  [dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
  [dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
    background-color: rgba(50, 61, 76, 1) !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }

  #lottie {
    display: none;
  }

  .caption-table {
    /* Heading/H4 */

    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    padding-left: 40px;
    color: #5e5873;
  }

  .row-label {
    /* Body/Paragraph Semi Bold */

    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    /* identical to box height, or 150% */

    align-items: center;

    /* Typography/Body */

    color: #6e6b7b;
  }

  .border-group {
    border-bottom: 2px solid #d8d6de;
  }

  .border-group-details {
    border-bottom: 1px solid rgba(31, 41, 47, 0.05);
  }

  .border-child {
    border-bottom: 2px solid white;
  }

  .child-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
  }

  .border-footer {
    border-top: 1px solid rgba(92, 177, 231, 1);
  }

  .invoice-numbercs {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
  }

  .header-label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .header-label-grey {
    /* Table/Table Header */

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height */

    text-align: right;
    letter-spacing: 1px;
    text-transform: uppercase;

    /* Typography/Muted & Placeholder */

    color: #b9b9c3;
  }

  .header-subtotal {
    font-style: normal;
    font-weight: 600;
    font-size: 11.9px;
    line-height: 12px;
    /* identical to box height, or 100% */

    text-align: right;
    letter-spacing: 0.14px;

    /* Typography/Body */

    color: #6e6b7b;
  }

  .project-sqft {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    /* Typography/Heading & Display */

    color: #5e5873;
  }

  .project-sqft-total {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    text-align: end;

    /* Theme Colour / Primary */

    // color: rgba(92, 177, 231, 1);
  }

  .width-100p {
    width: 100%;
  }
  .credit {
    // color: rgba(92, 177, 231, 1);
  }
  hr {
    display: block;
    height: 1px;
    background: transparent;
    width: 100%;
  }

  th {
    text-align: left !important;
  }
}

th {
  text-align: left !important;
  padding: 18px !important;
}

.comment-label {
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.caption-table {
  /* Heading/H4 */

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  padding-left: 40px;
  color: #5e5873;
}

.row-label {
  /* Body/Paragraph Semi Bold */

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  /* identical to box height, or 150% */

  align-items: center;

  /* Typography/Body */

  color: #6e6b7b;
}

.border-group {
  border-bottom: 2px solid #d8d6de;
}

.border-group-details {
  border-bottom: 1px solid #1f292f0d;
}

.border-child {
  border-bottom: 2px solid white;
}

.child-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.border-footer {
  border-top: 2px solid #6e6b7b;
}

.invoice-numbercs {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
}

.header-label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.header-label-grey {
  /* Table/Table Header */

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height */

  text-align: right;
  letter-spacing: 1px;
  text-transform: uppercase;

  /* Typography/Muted & Placeholder */

  color: #b9b9c3;
}

.header-subtotal {
  font-style: normal;
  font-weight: 600;
  font-size: 11.9px;
  line-height: 12px;
  /* identical to box height, or 100% */

  text-align: right;
  letter-spacing: 0.14px;

  /* Typography/Body */

  color: #6e6b7b;
}

.project-sqft {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  /* Typography/Heading & Display */

  color: #5e5873;
}

.project-sqft-total {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-align: end;

  /* Theme Colour / Primary */

  color: #6e6b7b;
}

.width-100p {
  width: 100%;
}

.child-line {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
}

.project-total-label {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #5e5873;
}

.project-total-number {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #6e6b7b;
}

.op-sc-details {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
}

.disclaimer {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
}
.confidential-line {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
  text-transform: uppercase;
}

.credit {
  color: #6e6b7b;
}

.section-description {
  color: #6e6b7b;
  font-size: 12px;
  font-weight: normal;
}

.base-paragraph {
  font-size: 12px;
}
.dpm-toggles {
  padding-top: 21px;
}
.text_summary {
  color: #6e6b7b;
  font-weight: 400;
}
//  new theming colors
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: rgba(50, 61, 76, 1) !important;
}

.table-background-color {
  color: azure !important;
}

.table_sub_header {
  background-color: rgb(235, 246, 252) !important;
}
.table_border_base {
  border-bottom: #6e6b7b solid 1px !important;
}
</style>
